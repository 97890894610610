import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import {
  DatoGatsbyImage,
  DatoStructuredText,
} from '@/features/common-blocks'
import { firstChild, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data: Queries.PersonFragment | null
  layout: 'LIGHTBOX' | 'PAGE'
}

export const PersonArticle = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const styles = {
    article: css`
      display: grid;
      position: relative;
      grid-template-columns: auto 1fr;
      grid-column-gap: var(--gtr-108);
      padding: var(--row-72) 0 var(--row-108);
      background: #fff;
      ${layout === 'PAGE' &&
      css`
        background: ${colors.gray95};
      `}
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        grid-column: 1 / 2;
        width: calc(100% + var(--gtr-108) / 3);
        height: 100%;
        background: ${colors.gray95};
        ${layout === 'PAGE' &&
        css`
          background: ${colors.navyLighter};
        `}
      }
      ${mq().m} {
        grid-template-columns: 1fr;
        &:before {
          display: none;
        }
      }
    `,
    image: css`
      width: calc(var(--fs-144) * 2);
      max-width: 100%;
      align-self: flex-start;
      margin-left: var(--margin);
      ${mq().m} {
        justify-self: center;
        margin-right: var(--margin);
      }
      ${mq().s} {
        width: calc(var(--fs-144) * 3);
      }
    `,
    text: css`
      margin-right: var(--margin);
      color: ${colors.navy};
      ${mq().m} {
        margin-left: var(--margin);
      }
    `,
    name: css`
      font-family: var(--ff-good-wide);
      font-weight: 700;
      text-transform: uppercase;
      margin: 1em 0 0.25em;
      ${mq().m} {
        text-align: center;
      }
    `,
    title: css`
      font-family: var(--ff-aktiv);
      font-size: var(--fs-21);
      font-weight: 400;
      font-style: italic;
      color: ${colors.gray40};
      ${mq().m} {
        text-align: center;
      }
    `,
    bio: css`
      margin-top: 2em;
      color: ${colors.gray30};
      ${firstChild} {
        margin-top: 0;
      }
    `,
  }
  return (
    <article
      css={styles.article}
      {...props}
    >
      <DatoGatsbyImage
        css={styles.image}
        image={data?.image?.gatsbyImageData}
        alt={data?.image?.alt || `${data?.name}, ${data?.title}`}
      />
      <div css={styles.text}>
        <h1 css={styles.name}>{data?.name}</h1>
        <h2 css={styles.title}>{data?.title}</h2>
        <div css={styles.bio}>
          <DatoStructuredText data={data?.bio} />
        </div>
      </div>
    </article>
  )
}

export const PersonFragment = graphql`
  fragment Person on DatoCmsPerson {
    __typename
    id: originalId
    name
    title
    bio {
      value
    }
    image {
      gatsbyImageData(
        width: 480
        imgixParams: { ar: "1:1", fit: "crop", crop: "focalpoint" }
      )
      alt
    }
    slug
    seoMetaTags {
      tags
    }
  }
`
